import { useEffect } from 'react';
import { useAmplitude } from 'hooks';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OVERVIEW_EVENT } from 'lib/tracking';
import './PromoBanner.scss';

const PromoBanner = () => {
  const { logEvent } = useAmplitude();
  const { userId } = useSelector((state) => state.user);

  // These image imports will be updated with new images on a regular basis.
  const upperPromoDesktop =
    require('../../../assets/hub_DT_OVERVIEW_1090x331.png').default;
  const upperPromoMobile =
    require('../../../assets/hub_MOB_OVERVIEW_344x294.5.png').default;

  const promoDesktop =
    require('../../../assets/ThreeTimesSamples_Desktop.png').default;
  const promoMobile =
    require('../../../assets/ThreeTimesSamples_Mobile.png').default;

  useEffect(() => {
    logEvent(`${OVERVIEW_EVENT}.dashboard.promo_banner.viewed`, {
      userId
    });
  }, [logEvent, userId]);

  return (
    <section data-testid='1234'>
      <Link
        className='promo_banner__link'
        onClick={() => {
          logEvent(`${OVERVIEW_EVENT}.dashboard.promo_banner.clicked`);
          window.open('https://hub.spri.ng/guides/spring-24', '_blank');
        }}
      >
        <picture className='upper_promo_banner'>
          <source srcSet={upperPromoMobile} media='(max-width: 1039px)' />
          <source srcSet={upperPromoDesktop} media='(min-width:1040)' />
          <img
            className='upper_promo_banner__image'
            src={upperPromoDesktop}
            alt='upper promo Banner'
          />
        </picture>
      </Link>
      <Link
        className='promo_banner__link'
        onClick={() => {
          logEvent(`${OVERVIEW_EVENT}.dashboard.promo_banner.clicked`);
          window.open('https://dashboard.teespring.com/samples', '_blank');
        }}
      >
        <picture>
          <source srcSet={promoMobile} media='(max-width: 1039px)' />
          <source srcSet={promoDesktop} media='(min-width:1040)' />
          <img
            className='promo_banner__image'
            src={promoDesktop}
            alt='promo Banner'
          />
        </picture>
      </Link>
    </section>
  );
};

export default PromoBanner;
