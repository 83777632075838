import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OVERVIEW_EVENT } from 'lib/tracking';
import { useGTM, useQuery, useAmplitude, useUserOrders } from 'hooks';
import { useFeatureFlag, FLAGS } from 'lib/featureFlags';
import { Loader } from '@teespring/ts-components';
import get from 'lodash/get';
import { delightedScript } from 'lib/delighted';
import useScript from 'hooks/useScripts';
// import useOnboardingChecklist from 'hooks/useOnboardingChecklist';
import axios from 'axios';
import WhatsNew from './WhatsNew';
import VerificationToast from './VerificationToast';
import EmailVerifyBar from './EmailVerifyBar';
import { API_HOST } from '../../constants';
// import OnboardingChecklist from './OnboardingChecklist';

import './Overview.scss';
import './Overview.css';

import IntegrationShelf from './IntegrationShelf';
import PromoBanner from './PromoBanner';
// import AnalyticsOverview from './AnalyticsOverview';
// import SpringBoardBanner from './SpringBoardBanner';

const Overview = () => {
  const emailVerificationEnabled = useFeatureFlag(FLAGS.EMAIL_VERIFICATION);
  // const [displayChecklist, setDisplayChecklist] = useState(true);

  // const { dismissed, dismissChecklist, checklist, loading } =
  //   useOnboardingChecklist();
  // useEffect(() => {
  //   setDisplayChecklist(!dismissed);
  // }, [dismissed]);
  const user = useSelector((state) => get(state, 'header.user'));
  const { userId } = useSelector((state) => state.user);
  // const handleDismissClick = () => {
  //   dismissChecklist();
  // };

  const isFetchingUserSubscriptions = useSelector(
    (state) => state.subscriptions.isFetchingUserSubscriptions
  );

  const { query } = useQuery();
  const { pushAccountToDataLayer } = useGTM();
  const { logEvent } = useAmplitude();

  const { verified, email } = user;

  // const recentlySignedUpUser = useSelector((state) =>
  //   get(state, 'user.recentlySignedUp', false)
  // );

  const needVerifyEmailBar =
    !query.get('verified') && emailVerificationEnabled && !verified;

  // setups
  useEffect(() => {
    logEvent(`${OVERVIEW_EVENT}.viewed`);
  }, [logEvent]);

  useEffect(() => {
    pushAccountToDataLayer();
  }, [pushAccountToDataLayer]);

  // delighted survey
  const { orders } = useUserOrders(user.id);
  const perPage = 10;

  const fetchListings = async () => {
    const { data } = await axios.get(`${API_HOST}/api/v1/user/listings`, {
      withCredentials: true,
      params: {
        userId,
        per: perPage,
        page: 1,
        sortBy: 'createdDate',
        orderBy: 'DESC'
      }
    });
    return data.listings;
  };
  let listings;
  const getListings = async () => {
    listings = await fetchListings();
  };
  useEffect(() => {
    getListings();
  }, []);
  useScript(
    delightedScript(user, listings?.length > 0, orders && orders?.length > 0)
  );

  return isFetchingUserSubscriptions ? (
    <Loader />
  ) : (
    <div className='overview_cont'>
      <div className='overview'>
        <VerificationToast className='overview__verification__toast' />
        <section className='overview__content'>
          {needVerifyEmailBar && <EmailVerifyBar email={email} />}
          <div className='firstBanner'>
           <PromoBanner />
          </div>
          {/* {displayChecklist && (
            <OnboardingChecklist
              loading={loading}
              checklist={checklist}
              onClick={handleDismissClick}
            />
          )} */}
          <div className='secondBanner'>
            {/* {!recentlySignedUpUser && <AnalyticsOverview />} */}

            {/* <SpringBoardBanner />*/}
          </div>
          {/* <Promotions /> */}
        </section>
      </div>
      <div className='thirdBanner'>
        <IntegrationShelf />
        <WhatsNew className='fourthBanner' />
      </div>
    </div>
  );
};

export default Overview;
