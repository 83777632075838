/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { isObjectEmpty } from 'lib/helpers';
import set from 'lodash/set';
import get from 'lodash/get';
// eslint-disable-next-line import/no-cycle
import { addToast } from 'redux/actions';
// eslint-disable-next-line import/no-cycle
import { ALL_FIELDS, HOME_FIELDS } from 'lib/hooks/storeEditor';
import { SERVERLESS_BASE_URL } from '../../../constants';
import { setUnauthorizedUser, setUserStoreNotFound } from '../user';

export const FETCH_STORE_THEME_CONFIG = 'FETCH_STORE_THEME_CONFIG';
export const UPDATE_THEME_CONFIG = 'UPDATE_THEME_CONFIG';
export const SET_STORE_PREVIEW_DATA = 'SET_STORE_PREVIEW_DATA';
export const DISCARD_PREVIEW_CHANGES = 'DISCARD_PREVIEW_CHANGES';
export const SET_PRISTINE = 'SET_PRISTINE';
export const SET_INITIAL_PREVIEW_DATA = 'SET_INITIAL_PREVIEW_DATA';
export const REMOVE_LOGO_IMAGE = 'REMOVE_LOGO_IMAGE';
export const REMOVE_HERO_BANNER_IMAGE = 'REMOVE_HERO_BANNER_IMAGE';
export const SET_IS_DIRTY = 'SET_IS_DIRTY';
export const SET_PERSISTED_PREVIEW_DATA = 'SET_PERSISTED_PREVIEW_DATA';

export const setInitalPreviewData = (initialData) => {
  return {
    type: SET_INITIAL_PREVIEW_DATA,
    payload: initialData
  };
};

export const setPersistedPreviewData = (data) => {
  return {
    type: SET_PERSISTED_PREVIEW_DATA,
    payload: data
  };
};

/**
 * Sets the store preview data
 *
 * @param {Object} data - Object describing preview data
 * @return {func} dispatch
 */
export const setStorePreviewData =
  (data = {}) =>
  (dispatch, getState) => {
    const { previewData, isDirty, initialPreviewData } =
      getState().storePreviewData;
    const isInitialState = isObjectEmpty(previewData);

    // Pre-populate with initial fields
    if (isInitialState) {
      dispatch(setInitalPreviewData(data));
      dispatch(setUserStoreNotFound(false));
      dispatch(setUnauthorizedUser(false));
      const { persistedPreviewData } = getState().storePreviewData;
      dispatch({
        type: SET_STORE_PREVIEW_DATA,
        data,
        isDirty: false
      });
      // If we have persisted data - apply that right after initial set of data
      if (!isObjectEmpty(persistedPreviewData)) {
        dispatch({
          type: SET_STORE_PREVIEW_DATA,
          data: { ...data, ...persistedPreviewData }
        });
        dispatch(setPersistedPreviewData({}));
      }
    } else {
      // Setting isDirty to true because we now have actual changes
      const hasChanges =
        !data.storefront &&
        JSON.stringify(data) !== JSON.stringify(previewData);

      let hasHomeChanged = false;
      let hasEditorChanged = false;
      const resolveStateDiff = (values, previewValues, initialState) => {
        const resolved = {};
        // HOME_FIELDS values are path keys that match the keys on `data` that is sent (such as meta.storeName).
        const homeFieldKeyPaths = Object.values(HOME_FIELDS);

        Object.keys(data).forEach((key) => {
          // If incoming data matches initial data, but does not match updates in the preview data
          if (
            values[key] !== previewValues[key] &&
            values[key] === initialState[key] &&
            previewValues[key].length === 0
          ) {
            resolved[key] = previewValues[key];
          } else {
            const initialValue = get(initialState, key, null);
            if (values[key] !== initialValue) {
              // If we are dealing with home page we set the isHomeDirty flag to true else isEditorDirty to true
              if (Object.values(homeFieldKeyPaths).includes(key)) {
                hasHomeChanged = true;
              } else {
                hasEditorChanged = true;
              }
            }
            resolved[key] = values[key];
          }
          // This is a boolean, we want all boolean values to take whatever is being updated
          if (key.includes('showSocialIcons')) resolved[key] = values[key];
        });
        return resolved;
      };

      const updatedState = resolveStateDiff(
        data,
        previewData,
        initialPreviewData
      );

      return dispatch({
        type: SET_STORE_PREVIEW_DATA,
        data: updatedState,
        isDirty:
          hasChanges && isDirty
            ? true
            : !hasChanges && isDirty
            ? true
            : hasChanges,
        isHomeDirty: hasHomeChanged,
        isEditorDirty: hasEditorChanged
      });
    }
  };

/**
 * Fetches stores theme config from AWS
 * @param  {string} storeKey  this is the stores slug
 * @return {func}             The dispatch function
 */

export const fetchThemeConfig = (slug) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${SERVERLESS_BASE_URL}/themes?siteKey=${slug}`
    );

    dispatch({
      type: FETCH_STORE_THEME_CONFIG,
      payload: data,
      slug
    });
  } catch (err) {
    dispatch(
      addToast(
        `Failed to fetch theme for store: ${slug}, ${
          err?.response?.data?.message ?? err.message
        }`,
        'danger'
      )
    );
  }
};

/**
 * Removes all changes to previewData or specific fields if specified
 * @fields {array[string]} key values from form to reset
 */

export const discardPreviewChanges =
  (fields = ALL_FIELDS) =>
  (dispatch, getState) => {
    const { initialPreviewData, previewData } = getState().storePreviewData;
    const overwriteData = fields.reduce((acc, key) => {
      acc[key] = initialPreviewData[key];
      return acc;
    }, {});

    return dispatch(setStorePreviewData({ ...previewData, ...overwriteData }));
  };

export const setPristine = () => {
  return {
    type: SET_PRISTINE
  };
};

export const updateThemeConfig =
  (storeKey, config) => async (dispatch, getState) => {
    const options = {
      headers: { Accept: '*/*', 'Content-Type': 'application/json' }
    };

    const acceptedStoreName = getState().store.name;
    set(config, HOME_FIELDS.storeName, acceptedStoreName);

    try {
      const { data } = await axios.put(
        `${SERVERLESS_BASE_URL}/update`,
        { storeKey, data: config },
        options
      );

      dispatch({
        type: UPDATE_THEME_CONFIG,
        payload: data
      });

      dispatch(setPristine());
    } catch (err) {
      dispatch(
        addToast(
          `Failed to update theme: ${
            err?.response?.data?.message ?? err.message
          }`
        )
      );
    }
  };

export const removeLogoImage = () => {
  return {
    type: REMOVE_LOGO_IMAGE
  };
};

export const removeHeroBannerImage = () => {
  return {
    type: REMOVE_HERO_BANNER_IMAGE
  };
};

export const setIsDirty = () => {
  return {
    type: SET_IS_DIRTY
  };
};
