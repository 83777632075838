// import { useSelector } from 'react-redux';
import map from 'lodash/map';
// import { v4 } from 'uuid';
// import WhatsNewTile from './components/WhatsNewTile';
import './WhatsNew.scss';
import { useAmplitude } from 'hooks';
import { useEffect } from 'react';

const WhatsNew = () => {
  const image1 = require('../../../assets/create_new_products.png').default;
  const image2 = require('../../../assets/whats_new_edit_store.png').default;
  const image3 = require('../../../assets/run_a_sale.png').default;
  const { logEvent } = useAmplitude();
  const imageSources = [image1, image2, image3];
  const gotoAction = (image) => {
    switch (image) {
      case image1:
        logEvent('quickaction.clicked', { image: image1 });
        window.open(
          'https://teespring.com/design-launcher/pick/9f34f3df-afd9-4299-b8d2-a72d920b5edd',
          '_self'
        );
        break;
      case image2:
        logEvent('quickaction.clicked', { image: image2 });
        window.open('https://dashboard.teespring.com/stores', '_self');
        break;
      case image3:
        logEvent('quickaction.clicked', { image: image3 });
        window.open('https://teespring.com/dashboard/promotions', '_self');
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    logEvent('quickaction.viewed');
  });
  return (
    <>
      <h1
        className='mb1'
        style={{ marginLeft: '30px', backgroundColor: 'transparent' }}
      >
        Quick actions
      </h1>
      <div className='overview__whatsnew'>
        {map(imageSources, (props, index) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            className='newImages'
            src={props}
            alt={index}
            onClick={() => gotoAction(props)}
          />
        ))}
      </div>
    </>
  );
};

export default WhatsNew;
