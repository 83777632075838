import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import {
  REDIRECT_TO_STRIPE_CHECKOUT,
  setIsCheckingOut
} from 'redux/actions/customDomains';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import jwt from 'jsonwebtoken';
import { hideModal, unhideModal, clearModalState } from '../modal';
import {
  STRIPE_PUBLISHABLE_KEY,
  SUBSCRIPTION_API_GATEWAY_KEY,
  SUBSCRIPTIONS_API_HOST,
  SERVERLESS_BASE_URL,
  GRAPHQL_API_HOST
} from '../../../constants';
import { addToast } from '../toast';
import { SUBSCRIPTION_STATES } from '../../../constants/subscriptionStates';

export const FETCHING_SERVICE_INFO_FAILED = 'FETCHING_SERVICE_INFO_FAILED';
export const IS_FETCHING_SERVICE_INFO = 'IS_FETCHING_SERVICE_INFO';
export const IS_FETCHING_SERVICE_INFO_DONE = 'IS_FETCHING_SERVICE_INFO_DONE';
export const IS_FETCHING_USER_SUBSCRIPTIONS = 'IS_FETCHING_USER_SUBSCRIPTIONS';
export const IS_FETCHING_USER_SUBSCRIPTIONS_DONE =
  'IS_FETCHING_USER_SUBSCRIPTIONS_DONE';
export const RECEIVE_SUBSCRIPTION_SERVICES = 'RECEIVE_SUBSCRIPTION_SERVICES';
export const RECEIVE_USER_SUBSCRIPTIONS = 'RECEIVE_USER_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION_STATUS = 'UPDATE_SUBSCRIPTION_STATUS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const IS_FETCHING_PAYMENT_METHODS = 'IS_FETCHING_PAYMENT_METHODS';
export const IS_EDITING_PAYMENT_METHODS = 'IS_EDITING_PAYMENT_METHODS';
export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD';
export const IS_CONFIRMING_PURCHASE = 'IS_CONFIRMING_PURCHASE';
export const CONFIRM_PURCHASE = 'CONFIRM_PURCHASE';
export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID';
export const PAYMENT_METHOD_HAS_SUBSCRIPTION =
  'PAYMENT_METHOD_HAS_SUBSCRIPTION';
export const RESUBSCRIBE_SUBSCRIPTION_ID = 'RESUBSCRIBE_SUBSCRIPTION_ID';

// Subscription Types from Subscription Coordinator
export const SUB_TYPE_PURCHASE_DOMAIN = 1;
export const SUB_TYPE_CONNECT_DOMAIN = 2;

//helpers
const subscriptionHeaders = {
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': SUBSCRIPTION_API_GATEWAY_KEY
  }
};

export const newSubscriptionServiceHeaders = (userId) => {
  const token = jwt.sign(
    { userId },
    process.env.REACT_APP_ONBOARDING_SERVICE_SECRET || 'secret'
  );
  const bearer = `Bearer ${token}`;

  const headers = formatAxiosRequestHeaders(undefined, bearer);
  return headers;
};

// logos
const logoBase =
  'https://teespring-ass.s3.amazonaws.com/Subscriptions/PaymentMethods/logo/';
const availableLogos = [
  'visa',
  'jcb',
  'discover',
  'amex',
  'mastercard',
  'unionpay'
];

const getLogoUrl = (cardBrand) => {
  if (availableLogos.includes(cardBrand)) {
    return `${logoBase}${cardBrand}.png`;
  } else {
    return undefined;
  }
};

// pure actions
export const fetchingServiceInfo = () => ({ type: IS_FETCHING_SERVICE_INFO });
export const fetchingServiceInfoComplete = () => ({
  type: IS_FETCHING_SERVICE_INFO_DONE
});
export const fetchingServiceInfoFailed = () => ({
  type: FETCHING_SERVICE_INFO_FAILED
});
export const fetchingUserSubscriptions = () => ({
  type: IS_FETCHING_USER_SUBSCRIPTIONS
});
export const fetchingUserSubscriptionsComplete = () => ({
  type: IS_FETCHING_USER_SUBSCRIPTIONS_DONE
});
export const isEditingPaymentMethod = (isEditing) => ({
  type: IS_EDITING_PAYMENT_METHODS,
  isEditing
});

export const isConfirmingPurchase = (confirmationState) => ({
  type: IS_CONFIRMING_PURCHASE,
  isConfirmingPurchase: confirmationState
});
export const setPaymentMethods = (paymentMethods) => ({
  type: SET_PAYMENT_METHODS,
  paymentMethodData: paymentMethods
});

// Stripe Customer Id
export const setCustomerId = (id) => ({
  type: SET_CUSTOMER_ID,
  customerId: id
});

export const paymentMethodHasSubscription = (value) => ({
  type: PAYMENT_METHOD_HAS_SUBSCRIPTION,
  paymentMethodHasSubscription: value
});

export const setResubscribeSubscriptionId = (id) => ({
  type: RESUBSCRIBE_SUBSCRIPTION_ID,
  paymentVendorSubscriptionId: id
});

const setupAndConfirmStripePaymentMethod = async (
  stripe,
  cardElement,
  email,
  name,
  newSubService,
  dispatch,
  getState
) => {
  const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
    card: cardElement,
    type: 'card',
    billing_details: { email, name }
  });
  if (pmError) throw pmError;

  let clientSecret = '';

  if (newSubService) {
    const { id: userId } = get(getState(), 'header.user');
    const mutationName = 'CreateSetupIntent';
    const payload = {
      query: `
      mutation CreateSetupIntent($paymentMethod: String!) {
        createSetupIntent(payment_method: $paymentMethod) {
          clientSecret
        }
      }`,
      variables: {
        paymentMethod: `${paymentMethod.id}`
      },
      operationName: `${mutationName}`
    };

    const headers = newSubscriptionServiceHeaders(userId);
    const { data } = await axios.post(GRAPHQL_API_HOST, payload, headers);
    clientSecret = data.data.createSetupIntent.clientSecret;
  } else {
    const { data } = await axios.post(
      `${SUBSCRIPTIONS_API_HOST}/v1/setup-intent`,
      { paymentMethodId: paymentMethod.id },
      subscriptionHeaders
    );

    clientSecret = data.clientSecret;
  }

  const { error: siError, setupIntent } = await stripe.retrieveSetupIntent(
    clientSecret
  );
  if (siError) throw siError;
  if (setupIntent.next_action) {
    dispatch(hideModal());
  }

  try {
    const { setupIntent: confirmIntent, error: confirmError } =
      await stripe.confirmCardSetup(
        clientSecret,
        { payment_method: paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) throw confirmError;

    if (confirmIntent.next_action) {
      dispatch(hideModal());
      const { error: err } = await stripe.handleNextAction({ clientSecret });
      if (err) throw err;
    }
  } finally {
    dispatch(unhideModal());
  }

  return paymentMethod;
};

// thunked actions
export const fetchPaymentMethods =
  (userId, newSubService = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: IS_FETCHING_PAYMENT_METHODS, isFetching: true });
      const mutationName = 'FetchPaymentMethods';
      const payload = {
        query: `
      query FetchPaymentMethods($userId: String!) {
        fetchPaymentMethods(userId: $userId) {
          payment_methods {
            brand
            created
            customer_id
            id
            last4
            logo
          }
        }
      }`,
        variables: {
          userId: `${userId}`
        },
        operationName: `${mutationName}`
      };

      const headers = newSubscriptionServiceHeaders(userId);

      const { data } = newSubService
        ? await axios.post(GRAPHQL_API_HOST, payload, headers)
        : await axios.get(
            `${SUBSCRIPTIONS_API_HOST}/v1/payment-methods?userId=${userId}`,
            subscriptionHeaders
          );
      const paymentMethods = newSubService
        ? data.data.fetchPaymentMethods.payment_methods
        : data;
      dispatch(setPaymentMethods(paymentMethods));
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? err.message;
      dispatch(
        addToast(`Failed to get payment methods. ${errorMessage}`, 'danger')
      );
    } finally {
      dispatch({ type: IS_FETCHING_PAYMENT_METHODS, isFetching: false });
    }
  };
export const deleteStripePaymentMethod =
  (paymentMethod, newSubService = true) =>
  async (dispatch, getState) => {
    let caughtError;
    try {
      dispatch(isEditingPaymentMethod(true));
      const { id: userId } = get(getState(), 'header.user');
      const {
        paymentMethod: { id: paymentMethodId, brand, last4 },
        index
      } = paymentMethod;
      const paymentMethods = get(getState(), 'subscriptions.paymentMethods');

      if (newSubService) {
        const mutationName = 'DetachPaymentMethod';
        const payload = {
          query: `
            mutation DetachPaymentMethod($paymentMethod: String!, $userId: String!) {
              detachPaymentMethod(payment_method: $paymentMethod, user_id: $userId) {
                id
              }
            }
          `,
          variables: {
            paymentMethod: paymentMethodId,
            userId: `${userId}`
          },
          operationName: `${mutationName}`
        };
        const headers = newSubscriptionServiceHeaders(userId);
        const result = await axios.post(GRAPHQL_API_HOST, payload, headers);
        if (result.data.errors) {
          dispatch(paymentMethodHasSubscription(true));
          throw new Error(
            result.data.errors[0].message ?? 'Error deleting payment method'
          );
        } else {
          paymentMethods.splice(index, 1);
          dispatch(setPaymentMethods(paymentMethods));

          dispatch(
            addToast(
              <>
                Successfully removed&nbsp;
                <strong>
                  {capitalize(brand)} **** {last4}
                </strong>
              </>,
              'success'
            )
          );
        }
      } else {
        await axios.delete(
          `${SUBSCRIPTIONS_API_HOST}/v1/payment-methods/${paymentMethodId}?userId=${userId}`,
          subscriptionHeaders
        );
        paymentMethods.splice(index, 1);
        dispatch(setPaymentMethods(paymentMethods));
        dispatch(
          addToast(
            <>
              Successfully removed&nbsp;
              <strong>
                {capitalize(brand)} **** {last4}
              </strong>
            </>,
            'success'
          )
        );
      }
    } catch (e) {
      caughtError = e;
      dispatch(addToast(`Failed to delete payment method. ${e}`, 'danger'));
    } finally {
      dispatch(isEditingPaymentMethod(false));
      if (!caughtError) dispatch(clearModalState());
    }
  };

export const updateSubscriptionStatus =
  ({ subscriptionPurchaseId, status }, useNewSubService) =>
  async (dispatch, getState) => {
    try {
      if (useNewSubService) {
        // Disabling reactivate for now until Subscriptions Coordinator can handle pending cancelled states
        const cancel = status === SUBSCRIPTION_STATES.canceled;
        const { id: userId } = get(getState(), 'header.user');
        const mutationName = `${cancel ? 'Cancel' : 'Resubscribe'}Subscription`;
        const payload = {
          query: `
          mutation ${mutationName}($subscriptionId: String!) {
            ${
              cancel ? 'cancel' : 'resubscribe'
            }Subscription(subscriptionId: $subscriptionId) {
              id
            }
          }
          `,
          variables: {
            subscriptionId: subscriptionPurchaseId
          },
          operationName: mutationName
        };
        const headers = newSubscriptionServiceHeaders(userId);
        try {
          await axios.post(GRAPHQL_API_HOST, payload, headers);
        } catch (err) {
          throw err;
        }
        dispatch(
          addToast(
            `Subscription has been ${
              cancel ? 'cancelled' : 'reactivated'
            }. Please allow a few minutes for your account to update.`,
            'success'
          )
        );
      } else {
        await axios.patch(
          `${SUBSCRIPTIONS_API_HOST}/v1/subscriptions/${subscriptionPurchaseId}`,
          { status },
          subscriptionHeaders
        );
        dispatch({
          type: UPDATE_SUBSCRIPTION_STATUS,
          data: {
            status,
            subscriptionId: subscriptionPurchaseId
          }
        });
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? err.message;
      dispatch(
        addToast(`Subscription failed to update. ${errorMessage}`, 'danger')
      );
    }
  };

export const createStripePaymentMethod =
  (stripe, formData, cardElement, newSubService = true) =>
  async (dispatch, getState) => {
    let caughtError;
    try {
      dispatch(isEditingPaymentMethod(true));
      const { id: userId, email } = get(getState(), 'header.user');
      const { name } = formData;
      const paymentMethod = await setupAndConfirmStripePaymentMethod(
        stripe,
        cardElement,
        email,
        name,
        newSubService,
        dispatch,
        getState
      );

      const { brand, last4 } = paymentMethod.card;

      const { paymentMethods, paymentVendorSubscriptionId } = get(
        getState(),
        'subscriptions'
      );

      if (newSubService) {
        let result = {};
        const metadataTags = { userId };
        const mutationName = 'CreateStripeCustomer';
        const payload = {
          query: `
      mutation CreateStripeCustomer($metadata: String!, $paymentMethod: String!, $email: String!, $name: String!) {
        createCustomer(metadata: $metadata, payment_method: $paymentMethod, email: $email, name: $name) {
          id
        }
      }
      `,
          variables: {
            paymentMethod: `${paymentMethod.id}`,
            email,
            name,
            metadata: JSON.stringify(metadataTags)
          },
          operationName: `${mutationName}`
        };
        const headers = newSubscriptionServiceHeaders(userId);
        result = await axios.post(GRAPHQL_API_HOST, payload, headers);

        if (result.data.errors) {
          const errorMessage = result.data.errors[0].message;
          throw new Error(errorMessage);
        } else {
          dispatch(setCustomerId(result.data.data.createCustomer.id));
          const logo = getLogoUrl(brand);
          paymentMethods.push({ ...paymentMethod, brand, last4, logo });
          dispatch(setPaymentMethods(paymentMethods));

          if (formData.showSuccessToast !== false) {
            dispatch(
              addToast(
                <>
                  Successfully added&nbsp;
                  <strong>
                    {capitalize(brand)} **** {last4}
                  </strong>
                </>,
                'success'
              )
            );
          }
        }
        // SUBS-372: resubscribe if payment method is accepted
        if (paymentVendorSubscriptionId) {
          await dispatch(
            updateSubscriptionStatus(
              {
                subscriptionPurchaseId: paymentVendorSubscriptionId,
                status: SUBSCRIPTION_STATES.active
              },
              true
            )
          );
          await dispatch(setResubscribeSubscriptionId(''));
        }
      } else {
        const { data: paymentVendorData } = await axios.get(
          `${SUBSCRIPTIONS_API_HOST}/v1/payment-vendors`,
          subscriptionHeaders
        );

        const paymentVendorId = get(
          find(paymentVendorData, { name: 'stripe' }),
          'id'
        );

        const { data: newPaymentMethod } = await axios.post(
          `${SUBSCRIPTIONS_API_HOST}/v1/payment-methods`,
          {
            paymentVendor: {
              id: paymentVendorId,
              paymentMethodId: paymentMethod.id,
              brand
            },
            user: { email, id: userId, name }
          },
          subscriptionHeaders
        );

        paymentMethods.push({ ...newPaymentMethod, brand, last4 });
        dispatch(setPaymentMethods(paymentMethods));

        if (formData.showSuccessToast !== false) {
          dispatch(
            addToast(
              <>
                Successfully added&nbsp;
                <strong>
                  {capitalize(brand)} **** {last4}
                </strong>
              </>,
              'success'
            )
          );
        }
      }
    } catch (err) {
      caughtError = err;
      const errorMessage = err?.response?.data?.message ?? err.message;
      dispatch(
        addToast(`Failed to add payment method. ${errorMessage}`, 'danger')
      );
    } finally {
      dispatch(isEditingPaymentMethod(false));
      if (formData.returnToPurchase && !caughtError) {
        formData.returnToPurchase();
      } else if (!caughtError) {
        dispatch(clearModalState());
      }
    }
  };

export const editStripePaymentMethod =
  (stripe, formData, cardElement, newSubService = true) =>
  async (dispatch, getState) => {
    try {
      dispatch(isEditingPaymentMethod(true));
      const { id: userId, email } = get(getState(), 'header.user');
      const { paymentMethods } = get(getState(), 'subscriptions');
      const {
        name,
        paymentMethodToEdit: {
          index: paymentMethodIndex,
          paymentMethod: paymentMethodToEdit
        }
      } = formData;
      const stripePaymentMethod = await setupAndConfirmStripePaymentMethod(
        stripe,
        cardElement,
        email,
        name,
        newSubService,
        dispatch,
        getState
      );

      const { brand, last4 } = stripePaymentMethod.card;

      if (newSubService) {
        const mutationName = 'EditPaymentMethod';
        const payload = {
          query: `
            mutation EditPaymentMethod($newPaymentMethod: String!, $currentPaymentMethod: String!, $userId: String!) {
              editPaymentMethod(new_payment_method: $newPaymentMethod, current_payment_method: $currentPaymentMethod, user_id: $userId) {
                id
              }
            }
            `,
          variables: {
            newPaymentMethod: stripePaymentMethod.id,
            currentPaymentMethod: paymentMethodToEdit.id,
            userId: `${userId}`
          },
          operationName: `${mutationName}`
        };
        const headers = newSubscriptionServiceHeaders(userId);
        await axios.post(GRAPHQL_API_HOST, payload, headers);

        const logo = getLogoUrl(brand);
        paymentMethods.splice(paymentMethodIndex, 1, {
          id: stripePaymentMethod.id,
          customer_id: paymentMethodToEdit.customer,
          created: new Date(stripePaymentMethod.created).toISOString(),
          brand,
          last4,
          logo
        });
        dispatch(paymentMethodHasSubscription(false));
        dispatch(setPaymentMethods(paymentMethods));
        dispatch(
          addToast(
            <>
              Successfully edited&nbsp;
              <strong>
                {capitalize(brand)} ****{last4}
              </strong>
            </>,
            'success'
          )
        );
      } else {
        const { data: editedPaymentMethod } = await axios.put(
          `${SUBSCRIPTIONS_API_HOST}/v1/payment-methods/${paymentMethodToEdit.id}`,
          {
            paymentVendorPaymentMethodId: stripePaymentMethod.id,
            paymentVendorCustomerId:
              paymentMethodToEdit.payment_vendor_customer_id,
            paymentVendorId: paymentMethodToEdit.payment_vendor_id,
            userId: paymentMethodToEdit.user_id
          },
          subscriptionHeaders
        );

        paymentMethods.splice(paymentMethodIndex, 1, {
          ...editedPaymentMethod,
          brand,
          last4
        });

        dispatch(setPaymentMethods(paymentMethods));
        dispatch(
          addToast(
            <>
              Successfully edited&nbsp;
              <strong>
                {capitalize(brand)} ****{last4}
              </strong>
            </>,
            'success'
          )
        );
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message ?? err.message;
      dispatch(
        addToast(`Failed to edit payment method. ${errorMessage}`, 'danger')
      );
    } finally {
      dispatch(isEditingPaymentMethod(false));
      if (formData.returnToPurchase) {
        formData.returnToPurchase();
      } else {
        dispatch(clearModalState());
      }
    }
  };

export const fetchSubscriptionService =
  (serviceId, headers, isNewSubscriptionService = false) =>
  async (dispatch) => {
    try {
      dispatch(fetchingServiceInfo());
      const payload = {
        query: `query ToolsPageMetadata($subscriptionProductId: String!) {
          getSubscriptionsWithToolsPageMetadata(subscriptionProductId: $subscriptionProductId) {
            description
            id
            metaData {
              id
              data {
                emailTemplateId
                icon {
                  alt
                  url
                }
                legalDisclaimer
                mainImage {
                  alt
                  url
                }
                nextSteps
                overView
                purchaseCompleteURL
                subscription_product_id
                supportLink
                whatsIncluded {
                  icon
                  isNew
                  text
                }
              }
            }
            name
            priceOptions {
              count
              currency
              id
              interval
              price
              recurring
              renewal_period
            }
            product_name
            status
          }
        }
        `,
        variables: {
          subscriptionProductId: `${serviceId}`
        },
        operationName: 'ToolsPageMetadata'
      };
      const { data } = isNewSubscriptionService
        ? await axios.post(GRAPHQL_API_HOST, payload, headers)
        : await axios.get(
            `${SUBSCRIPTIONS_API_HOST}/v1/services?id=${serviceId}`,
            subscriptionHeaders
          );

      dispatch({
        type: RECEIVE_SUBSCRIPTION_SERVICES,
        data: isNewSubscriptionService
          ? data.data.getSubscriptionsWithToolsPageMetadata
          : data,
        serviceId
      });
      dispatch(fetchingServiceInfoComplete());
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
      dispatch(fetchingServiceInfoFailed());
      dispatch(fetchingServiceInfoComplete());
    }
  };

/** Fetches all subscriptions for a user */
const newSubToOldSubReduxFormat = (user_id) => {
  return (newSub) => {
    const { paymentVendorSubscriptionData, id, subscriptionData } = newSub;
    return {
      id,
      user_id,
      subscriptionData,
      ...paymentVendorSubscriptionData
    };
  };
};

const fetchUserSubscriptionFromSubscriptionCoordinator = async (userId) => {
  const queryName = 'GetUserSubscriptions';
  const payload = {
    query: `
query ${queryName}($userId: String!) {
  getUserSubscriptions(userId: $userId) {
    subscriptions {
      id
      productsList {
        id
        product_name
        name
        description
        status
        priceOptions {
          id
          price
          currency
          recurring
          interval
          count
          renewal_period
        }
        metaData {
          id
          data {
            subscription_product_id
            overView
            nextSteps
            purchaseCompleteURL
            legalDisclaimer
            supportLink
            emailTemplateId
            icon {
              url
              alt
            }
            mainImage {
              url
              alt
            }
            whatsIncluded {
              icon
              text
              isNew
            }
          }
        }
      }
      subscriptionData {
        type
        data {
          ... on ConnectedDomainSubscriptionData {
            storeId
            domainsList
            domainIdsList
            netlifySiteId
            primary
            status
            isRegisteredWithSpring
            facebookPixelVerificationCode
            cancelAtPeriodEnd
          }
          ... on PurchasedDomainSubscriptionData {
            storeId
            domain
            domainId
            netlifySiteId
            primary
            status
            isRegisteredWithSpring
            facebookPixelVerificationCode
            cancelAtPeriodEnd
          }
        }
      }
      expirationDate
      paymentVendor
      paymentVendorSubscriptionId
      paymentVendorSubscriptionData {
        id
        auto_renew
        created_at
        renewal_period_start
        expires_at
        creatorToolPrice {
          id
          price
          currency
          recurring
          interval
          count
          renewal_period
          creatorTool {
            id
            name
            description
            status
          }
        }
      }
    }
  }
}`,
    variables: {
      userId: `${userId}`
    },
    operationName: queryName
  };

  const bearerToken = jwt.sign(
    { userId },
    process.env.REACT_APP_ONBOARDING_SERVICE_SECRET || 'secret'
  );
  try {
    const { data } = await axios.post(
      GRAPHQL_API_HOST,
      payload,
      formatAxiosRequestHeaders(undefined, `Bearer ${bearerToken}`)
    );
    const {
      data: {
        getUserSubscriptions: { subscriptions }
      }
    } = data;

    return subscriptions.map(newSubToOldSubReduxFormat(userId));
  } catch (err) {
    console.log('Error getting user subscriptions', err);
    throw new Error('Unable to get user subscriptions');
  }
};

export const isSubscriptionActive = (subsData) =>
  subsData.data.status === 'active';

export const fetchUserSubscriptions =
  (userId, useNewSubscriptionCoordinator = false) =>
  async (dispatch, getState) => {
    userId = userId || getState().user.userId;

    try {
      dispatch(fetchingUserSubscriptions());

      let transformedData = {};
      if (useNewSubscriptionCoordinator) {
        const data = await fetchUserSubscriptionFromSubscriptionCoordinator(
          userId
        );
        transformedData = {
          active: data.filter((s) => isSubscriptionActive(s.subscriptionData)),
          inactive: data.filter(
            (s) => !isSubscriptionActive(s.subscriptionData)
          )
        };
      } else {
        const { data } = await axios.get(
          `${SUBSCRIPTIONS_API_HOST}/v1/subscriptions?userId=${userId}`,
          subscriptionHeaders
        );
        transformedData = {
          active: keyBy(data.active, (object) => object.id),
          inactive: keyBy(data.inactive, (object) => object.id)
        };
      }

      dispatch({
        type: RECEIVE_USER_SUBSCRIPTIONS,
        data: transformedData
      });
      dispatch(fetchingUserSubscriptionsComplete());
    } catch (err) {
      dispatch(
        addToast('Unable to load subscriptions. Please try again.', 'danger')
      );
      dispatch(fetchingUserSubscriptionsComplete());
    }
  };

export const redirectToSubscriptionToolCheckout =
  ({ serviceId, priceOption }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCheckingOut(true));

      const { header } = getState();
      const userEmail = get(header, 'user.email');
      const userId = get(header, 'user.id');
      const origin = get(window, 'location.origin', 'https://spri.ng');

      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      const { data } = await axios.post(
        `${SUBSCRIPTIONS_API_HOST}/v1/checkouts/create-session`,
        {
          cancelUrl: `${origin}/tools/${serviceId}`,
          customerEmail: userEmail,
          userId,
          lineItems: [
            {
              creatorToolId: serviceId,
              creatorToolPriceId: get(priceOption, 'id'),
              price: get(priceOption, 'price', 0),
              quantity: 1
            }
          ],
          paymentVendorName: 'stripe',
          successUrl: `${origin}/tools/thank-you?serviceId=${serviceId}`
        },
        subscriptionHeaders
      );

      const result = await stripe.redirectToCheckout({ sessionId: data.id });
      if (result?.error) {
        throw result.error;
      }

      dispatch({
        type: REDIRECT_TO_STRIPE_CHECKOUT,
        data: { sessionId: data.id }
      });
    } catch (err) {
      dispatch(addToast(err?.response?.data?.message ?? err.message, 'danger'));
    } finally {
      setTimeout(() => dispatch(setIsCheckingOut(false)), 2000);
    }
  };

export const registerDomain = (domainRegistationInfo) => {
  return async (paymentIntent, getState) => {
    try {
      const { slug, id: storeId } = get(getState(), 'store', 0);
      const { id: userId } = get(getState(), 'header.user');
      await axios.post(`${SERVERLESS_BASE_URL}/register-domain`, {
        domain: domainRegistationInfo,
        payment_intent: paymentIntent,
        userId,
        storeId,
        storeName: `${slug}-${domainRegistationInfo.domainName}`.replace(
          /\./g,
          '-'
        )
      });
    } catch (e) {
      throw e;
    }
  };
};

export const createPaymentIntent =
  ({
    priceId,
    creatorToolName,
    metadata = undefined,
    postPaymentAction = undefined,
    iterableCampaignId = undefined,
    iterableTemplateId = undefined
  }) =>
  async (dispatch, getState) => {
    const { email, id: userId } = get(getState(), 'header.user');
    const { payment_vendor_customer_id, payment_vendor_payment_method_id } =
      get(getState(), 'subscriptions.paymentMethods[0]');

    try {
      dispatch(isConfirmingPurchase(true));
      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      const {
        data: { client_secret, payment_intent_id }
      } = await axios.post(
        `${SUBSCRIPTIONS_API_HOST}/v1/payment-intent`,
        {
          customerEmail: email,
          userId,
          creatorToolPriceId: priceId,
          customerId: payment_vendor_customer_id,
          metadata,
          iterableCampaignId,
          iterableTemplateId
        },
        subscriptionHeaders
      );

      const { error } = await stripe.confirmCardPayment(client_secret, {
        payment_method: payment_vendor_payment_method_id
      });
      if (error) throw error;

      if (postPaymentAction) {
        await postPaymentAction(payment_intent_id, getState);
      }

      dispatch({
        type: 'SET_ACTIVE_MODAL',
        id: 'purchase-checkout-confirmation-modal',
        props: {
          creatorToolName
        }
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? error.message;
      dispatch(
        addToast(`Failed to confirm payment. ${errorMessage}`, 'danger')
      );
    } finally {
      dispatch(isConfirmingPurchase(false));
    }
  };

export const createSubscription =
  ({
    creatorToolName,
    creatorToolId,
    customerId,
    paymentMethod,
    metadata = undefined,
    userId = undefined,
    storeSlug = undefined,
    storeId = undefined,
    storeName = undefined
  }) =>
  async (dispatch, getState) => {
    const { email } = get(getState(), 'header.user');
    try {
      dispatch(isConfirmingPurchase(true));
      const mutationName = 'CreateSubscription';
      const subscriptionsMetadata = {
        ...metadata,
        domain: metadata !== undefined ? metadata.domainName : '',
        storeSlug,
        userId,
        creatorToolId,
        storeId,
        storeName,
        email
      };

      if (!customerId) customerId = paymentMethod.customer_id;

      const items = [
        {
          count: 1,
          currency: 'usd',
          interval: 'year',
          product_id: creatorToolId,
          quantity: 1
        }
      ];
      const payload = {
        query: `
      mutation CreateSubscription($items: [SubscriptionItem!]!, $metadata: String!, $customer: String!) {
        createSubscription(items: $items, metadata: $metadata, customer: $customer) {
          id
          status
        }
      }
      `,
        variables: {
          items,
          metadata: JSON.stringify(subscriptionsMetadata),
          customer: customerId
        },
        operationName: `${mutationName}`
      };
      const headers = newSubscriptionServiceHeaders(userId);
      const result = await axios.post(GRAPHQL_API_HOST, payload, headers);

      if (result.data.errors) {
        const errorMessage = result.data.errors[0].message;
        console.log('errormessage ', errorMessage);
        throw new Error(errorMessage);
      }

      dispatch({
        type: 'SET_ACTIVE_MODAL',
        id: 'purchase-checkout-confirmation-modal',
        props: {
          creatorToolName
        }
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? error.message;
      dispatch(
        addToast(`Failed to confirm payment. ${errorMessage}`, 'danger')
      );
    } finally {
      dispatch(isConfirmingPurchase(false));
    }
  };
