export default [
  // {
  //   id: 1,
  //   displayName: 'DIFY',
  //   logoSrc: '',
  //   subHeading: '',
  //   heading: 'Affordable merch designs',
  //   body: 'Quickly set your store up for success with professionally-designed merch.',
  //   ctaText: 'Learn more ->',
  //   ctaLink: 'https://affinityx.creator-spring.com/',
  //   target: '_self',
  //   footnote: '',
  //   appIcon:
  //     'https://teespring-ass.s3.amazonaws.com/upgrades/designondemand.png',
  //   appDescription:
  //     'Quickly set your store up for success with professionally-designed merch.',
  //   appType: '',
  //   colSpan: 12,
  //   styles: {
  //     theme: 'light',
  //     align: 'left',
  //     jc: 'center',
  //     bgDesktop:
  //       'url("https://teespring-ass.s3.amazonaws.com/upgrades/DT_UPGRADES_983x400DIFY(1).png") center/cover',
  //     bgMobile:
  //       'url("https://teespring-ass.s3.amazonaws.com/upgrades/MOB_UPGRADES_327x280DIFY(1).png") center/cover',
  //     customStyles:
  //       'h3 {color: black}; p {color: black}; img:first-child {height: 40px}'
  //   }
  // },
  // {
  //   id: 1,
  //   displayName: 'Social Media Integrations',
  //   logoSrc: '',
  //   subHeading: '',
  //   heading: 'Boost merch sales',
  //   body: 'Sell your merch directly on your most popular social channels.',
  //   ctaText: 'Connect now ->',
  //   ctaLink: '/integrations/all',
  //   target: '_self',
  //   footnote: '',
  //   appIcon:
  //     'https://teespring-ass.s3.amazonaws.com/upgrades/DT_UPGRADES_ICON_1x.png',
  //   appDescription: 'Connect your products to your social accounts',
  //   appType: 'Integrate social media accounts',
  //   colSpan: 12,
  //   styles: {
  //     theme: 'light',
  //     align: 'left',
  //     jc: 'center',
  //     bgDesktop:
  //       'url(https://teespring-ass.s3.amazonaws.com/upgrades/DT_UPGRADES_983x400_1x.png) center/cover',
  //     bgMobile:
  //       'url(https://teespring-ass.s3.amazonaws.com/upgrades/MOB_UPGRADES_327x280_1x.png) center/cover',
  //     customStyles:
  //       'h3 {color: black}; p {color: black}; img:first-child {height: 40px}'
  //   }
  // },
  // {
  //   id: 1,
  //   displayName: 'Kajabi',
  //   logoSrc:
  //     'https://teespring-ass.s3.amazonaws.com/upgrades/kajabi_spring_logo.png',
  //   subHeading: '',
  //   heading: 'Considered making a course?',
  //   body: "Unlock 30 days of free access to Kajabi's course creation tools.\nStart monetizing your knowledge today.",
  //   ctaText: 'Claim Now ->',
  //   ctaLink: 'https://app.kajabi.com/r/QudfXKLc/t/2hwxlnjg',
  //   target: '_self',
  //   footnote: '',
  //   appIcon: 'https://teespring-ass.s3.amazonaws.com/upgrades/kajabi_icon.png',
  //   appDescription: 'Promote your merch directly on Kajabi!',
  //   appType: 'Selling tools',
  //   colSpan: 12,
  //   styles: {
  //     theme: 'dark',
  //     align: 'left',
  //     jc: 'center',
  //     bgDesktop:
  //       'url(https://teespring-ass.s3.amazonaws.com/upgrades/kajabi_desktop_bg.png) center/cover',
  //     bgMobile:
  //       'url(https://teespring-ass.s3.amazonaws.com/upgrades/kajabi_mobile_bg.png) center/cover',
  //     customStyles:
  //       'h3 {color: #0072ef}; p {color: black}; img:first-child {height: 40px}'
  //   }
  // },
  {
    id: 1,
    displayName: 'Purchase a domain',
    logoSrc: '',
    subHeading: ' ',
    heading: 'Purchase a custom domain in minutes.',
    body: '',
    ctaText: 'Buy now',
    ctaLink: '/stores/purchase-domain',
    featureImg:
      'https://teespring-ass.s3.amazonaws.com/upgrades/content-space.png',
    footnote: '',
    appIcon: 'https://teespring-ass.s3.amazonaws.com/upgrades/purchase.png',
    appDescription: 'Buy a domain for your Spring store',
    appType: 'Selling tools',
    colSpan: 6,
    styles: {
      theme: 'dark',
      align: 'left',
      jc: 'space-between',
      bgDesktop:
        'no-repeat url(https://teespring-ass.s3.amazonaws.com/upgrades/purchase_background.png) bottom/contain, repeat url(https://teespring-ass.s3.amazonaws.com/upgrades/DT_UPGRADES_background.png) top',
      bgMobile: '',
      txtColor: '#000000'
    }
  },
  {
    id: 2,
    displayName: 'Connect a domain',
    logoSrc: '',
    subHeading: '',
    heading: 'Have a domain? Connect it today to drive more sales.',
    body: '',
    ctaText: 'Connect now',
    ctaLink: '/tools/c1236644-2cec-11ec-8d3d-0242ac130003',
    footnote: '',
    appIcon: 'https://teespring-ass.s3.amazonaws.com/upgrades/connect.png',
    appDescription: 'Connect your existing domain',
    appType: 'Selling tools',
    colSpan: 6,
    styles: {
      theme: 'light',
      align: 'left',
      jc: 'space-between',
      bgDesktop:
        'url(https://teespring-ass.s3.amazonaws.com/upgrades/connect_background.png)',
      bgMobile: '',
      txtColor: '#000000',
      customStyles: 'button {margin-bottom: 38px;}'
    }
  },
  {
    id: 3,
    displayName: 'Mamenta',
    logoSrc: '',
    subHeading: '',
    heading: '',
    body: '',
    ctaText: '',
    ctaLink: 'https://www.mamenta.com/what-is-mamenta-watch-now',
    target: '_blank',
    footnote: '',
    appIcon: 'https://teespring-ass.s3.amazonaws.com/upgrades/mamenta_icon.png',
    appDescription: 'Your brand, everywhere.',
    appType: 'Connect tools',
    colSpan: 12,
    styles: {
      theme: 'light',
      align: 'left',
      jc: 'center',
      bgDesktop:
        '#030305 no-repeat url(https://teespring-ass.s3.amazonaws.com/upgrades/mamenta_desktop_980x400.jpg) center/contain',
      bgMobile:
        '#0e1837 no-repeat url(https://teespring-ass.s3.amazonaws.com/upgrades/mamenta_mobile_327x280.jpg) center/contain',
      txtColor: '#000000',
      customStyles: ''
    }
  },
  {
    id: 4,
    displayName: 'Amaze',
    logoSrc: 'https://teespring-ass.s3.amazonaws.com/upgrades/amaze-logo.png',
    subHeading: '',
    heading:
      'Amaze <span style="font-style:italic; font-weight: 400;margin-left: -7px;">with</span> Spring',
    body: "Boost sales of your Spring products using Amaze's<br> beautiful no-code product pages.",
    ctaText: 'Create now',
    ctaLink:
      'https://amaze.spri.ng/?utm_source=upgrades_page&utm_medium=affiliate&utm_campaign=amaze+x+spring',
    target: '_blank',
    footnote: '',
    appIcon:
      'https://teespring-ass.s3.amazonaws.com/upgrades/amaze-icon-new.png',
    appDescription: 'Create beautiful no-code product pages',
    appType: 'Selling tools',
    colSpan: 12,
    styles: {
      theme: 'dark',
      align: 'left',
      jc: 'center',
      bgDesktop:
        'url(https://teespring-ass.s3.amazonaws.com/upgrades/amaze-banner-bg.png) center/cover',
      bgMobile:
        'url(https://teespring-ass.s3.amazonaws.com/upgrades/amaze-banner-bg-mobile.png) center/cover',
      customStyles:
        'h3 {background: linear-gradient(146.62deg, #59A4F0 14.03%, #3755F0 86.36%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;}'
    }
  }
];
